import React from "react";

export default class HealthComponent extends React.Component{

    render(){
        return(
            <section className="rightbreak color-black-2">
                <section className="health-title color-white-3 bold-this">
                    Health
                </section>

                <section className="health-body color-white-3">

                </section>
            </section>
        );
    }

}