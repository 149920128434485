
import React from "react";
import ReactDOM from 'react-dom';
import App from "./App";


//DB Server

///////////////////////////////////////////////////////////////////////////////////////////This commented code breaks the app

/* const dbConfig = require("./database/config/db.config.js");
const Sequelize = require("sequelize");
const sequelize = new Sequelize(dbConfig.DB, dbConfig.USER, dbConfig.PASSWORD, {
    host: dbConfig.HOST,
    dialect: dbConfig.dialect,
    operatorsAliases: false,
    pool: {
    max: dbConfig.pool.max,
    min: dbConfig.pool.min,
    acquire: dbConfig.pool.acquire,
    idle: dbConfig.pool.idle
    }
});
const db = {};
db.Sequelize = Sequelize;
db.sequelize = sequelize;
db.foodDB = require("./database/models/fooddb.model.js")(sequelize, Sequelize);
module.exports = db; */


ReactDOM.render( <App />, document.getElementById("root"));

