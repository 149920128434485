import React from "react";

export default class ShoppingComponent extends React.Component {
    render() {
        return (
            /*The equivilate to the rightbreak*/
            <section className="main-section shop-color-2">
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
                <section className="main-content shop-color-3">
                    <div className="main-title">Shopping Here</div>
                </section>
            </section>
        );
    }
}