import React from "react";

export default class PersonalHomeComponent extends React.Component{
    render(){
        return(
            <section>
                <div>
                    personal
                </div>
            </section>
        );
    }
}