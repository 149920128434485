import React from "react";
import { useNavigate } from "react-router-dom";

function ShoppingNavbar() {
    let navigate = useNavigate();

    return (
        <section className="leftbreak shop-color-1">
            <span className="leftbreak-background-setter"></span>

            <section className="contact-info">
                <span className="br" id="bold-this">
                    Shopping
                </span>
                <span className="br">Null</span>
                <hr className="divider-line"></hr>
            </section>

            <section className="resources">
                <span className="br" id="bold-this">
                    Null
                </span>
                <span className="br">Null</span>
                <span className="br" id="bold-this">
                    {" "}
                    Null
                </span>
                <span className="br">Null</span>
                <span className="br" id="bold-this">
                    Null
                </span>
                <hr className="divider-line"></hr>
            </section>

            <section className="my-projects">
                <button onClick={() => extendProjectMenu()} className="dropbtnSHOP"></button>
                <div id="projectDropdownSHOP" className="project-dropdown-content-SHOP">
                    <span className="br">
                        <button
                            onClick={() => { navigate("/"); }}
                            id="link-button">Home</button>
                    </span>
                    <span className="br">
                    </span>
                </div>
            </section>

            {/* <ContactMe /> */}
        </section>
    );
}

export default ShoppingNavbar;

function extendProjectMenu() {
    document.getElementById("projectDropdownSHOP").classList.toggle("show");
}
window.onclick = function (event) {
    if (!event.target.matches('.dropbtnSHOP')) {
        var dropdowns = document.getElementsByClassName("project-dropdown-content-SHOP");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
}

/*run our contact me server */
// const { exec } = require("child_process");

// exec("node contactmeServer.js", (error, stdout, stderr) => {
//   if (error) {
//       console.log(`error: ${error.message}`);
//       return;
//   }
//   if (stderr) {
//       console.log(`stderr: ${stderr}`);
//       return;
//   }
//   console.log(`stdout: ${stdout}`);
// });